<template>
  <div class="container">
    <h4 class="mb-5">Application Form | Signature</h4>
    <div class="card">
      <div class="card-body">
        <Close @close="handleClear">clear</Close>
        <canvas id="canvas" width="400" height="200"></canvas>
      </div>
    </div>
    <button class="fabric-btn fabric-btn-submit" @click="saveSignature">
      Upload
    </button>
  </div>
</template>
<script>
import SignaturePad from "signature_pad";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Signature",
  data: () => ({
    signaturePad: null,
    uploading: false
  }),
  computed: {
    ...mapGetters(["getApplicationUuid"])
  },
  methods: {
    ...mapActions(["uploadFile"]),
    base64ToBlob(base64, mime) {
      mime = mime || "";
      var sliceSize = 1024;
      var byteChars = window.atob(base64);
      var byteArrays = [];

      for (
        var offset = 0, len = byteChars.length;
        offset < len;
        offset += sliceSize
      ) {
        var slice = byteChars.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: mime });
    },
    async saveSignature() {
      if (this.uploading) return;
      this.uploading = true;
      const data = this.signaturePad.toDataURL("image/jpg");
      const blob = this.base64ToBlob(
        data.replace(/^data:image\/(png|jpg);base64,/, ""),
        "image/jpg"
      );
      const formData = new FormData();
      const uuid =
        this.getApplicationUuid || "54401184-05d6-474b-aee6-32ad2348ab37";
      formData.append("file", blob);
      formData.append("type", "signature");
      formData.append("format", "html_input_file");
      formData.append("ext", "jpg");
      const response = await this.uploadFile({
        formData,
        uuid
      }).catch(err => {
        console.log(err);
      });

      if (response && response.status == 200) {
        this.uploading = false;
        this.$emit("next", "ThankYou");
      }
    },
    handleClear() {
      this.signaturePad.clear();
    }
  },
  mounted() {
    const canvas = document.querySelector("#canvas");
    this.signaturePad = new SignaturePad(canvas, {
      backgroundColor: "rgba(255, 255, 255, 0)",
      penColor: "rgb(0, 0, 0)"
    });
  }
};
</script>
<style lang="scss" scoped>
canvas {
  border: solid 1px grey;
}
</style>
