<template>
  <div class="container">
    <h4 class="mb-5">Application Form | About You</h4>
    <div class="row">
      <div class="col-12 d-flex justify-content-start">
        <div>
          <p class="text-left">Skip signature input
            <input
                id="toggleSigSwitch"
                :checked="getWithSig"
                class="toggleSwitch"
                type="checkbox"
                @change="handleToggleChange($event)"
            /><label class="toggleLabel" for="toggleSigSwitch"></label>
          </p>
        </div>

        <div class="ml-5">
          <p class="text-left">Skip employer input
            <input
                id="toggleEmpSwitch"
                :checked="getNoEmployerInput"
                class="toggleSwitch"
                type="checkbox"
                @change="handleToggleChange($event)"
            /><label class="toggleLabel" for="toggleEmpSwitch"></label>
          </p>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <Form
            :validation-schema="schema"
            class="w-100 text-left"
            @submit="handleNext"
        >
          <div class="row">
            <div class="col-auto">
              <TextInput
                  class="pr-1"
                  label="Title"
                  name="title"
                  placeholder="Mr"
                  type="text"
              />
            </div>
            <div class="col-auto">
              <TextInput
                  class="pr-1"
                  label="First Name"
                  name="first_name"
                  placeholder="Joe"
                  type="text"
              />
            </div>
            <div class="col-auto">
              <TextInput
                  label="Last Name"
                  name="last_name"
                  placeholder="Blogs"
                  type="text"
              />
            </div>
          </div>
          <TextInput
              label="Email"
              name="email"
              placeholder="joe@bloggs.com"
              type="text"
          />
          <!-- <TextInput
            name="dob"
            type="text"
            label="Date of birth"
            placeholder="06/11/1978"
            class="pr-1"
          /> -->
          <DatePicker label="Date of birth" name="dob" placeholder=""/>
          <TextInput
              label="National Insurance Number"
              name="ni_number"
              placeholder="JS123456C"
              type="text"
          />
          <TextInput label="Phone" name="phone" placeholder="" type="text"/>
          <TextInput
              label="Address Line 1"
              name="address_1"
              placeholder=""
              type="text"
          />
          <TextInput
              label="Address Line 2"
              name="address_2"
              placeholder=""
              type="text"
          />
          <TextInput
              label="Address Line 3"
              name="address_3"
              placeholder=""
              type="text"
          />
          <TextInput label="Town/City" name="town" placeholder="" type="text"/>
          <TextInput
              label="Post Code"
              name="postcode"
              placeholder=""
              type="text"
          />
          <button class="fabric-btn fabric-btn-submit">Next</button>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import * as Yup from "yup";
import dayjs from "dayjs";
import {mapActions, mapGetters, mapMutations} from "vuex";
import DatePicker from "@/components/form/DatePicker";

export default {
  name: "AboutYou",
  emits: ["next"],
  components: {
    DatePicker
  },
  setup() {
    const schema = Yup.object().shape({
      title: Yup.string().required("required"),
      first_name: Yup.string().required(),
      last_name: Yup.string().required(),
      dob: Yup.string().required(),
      ni_number: Yup.string().required(),
      email: Yup.string()
          .email()
          .required(),
      phone: Yup.string().required(),
      address_1: Yup.string().required(),
      town: Yup.string().required(),
      postcode: Yup.string()
          .required()
          .matches(/[A-Za-z]{1,2}[0-9]{1,2}[A-Za-z]?\s?[0-9]{1,2}[A-Za-z]{2}/, {
            message: "Must be a valid UK postcode",
            excludeEmptyString: true
          })
    });
    return {
      schema
    };
  },
  computed: {
    ...mapGetters([
      "getWithSig",
      "getNoEmployerInput",
    ])
  },
  methods: {
    ...mapActions(["submitApplication"]),
    ...mapMutations(["setApplicationUuid", "setWithSig", "setNoEmployerInput"]),
    async handleToggleChange(e) {
      switch (e.target.id) {
        case 'toggleSigSwitch':
          this.setWithSig(e.target.checked);
          break;
        case 'toggleEmpSwitch':
          this.setNoEmployerInput(e.target.checked);
          break
      }
    },
    async handleNext(params) {
      let tempParams = params;
      let addressArray = [
        {
          address_1: params.address_1,
          address_2: params.address_2,
          address_3: params.address_3,
          town: params.town,
          postcode: params.postcode,
          current: "1"
        }
      ];
      let contactArray = [
        {
          region: "+44",
          number: params.phone,
          current: "1"
        }
      ];
      tempParams.user_id = null;
      tempParams.address = addressArray;
      tempParams.contact = contactArray;
      tempParams.dob = dayjs(tempParams.dob).format("YYYY-MM-DD");
      const response = await this.submitApplication(tempParams).catch(err =>
          console.log(err)
      );
      this.setApplicationUuid(response.data.data.uuid);
      this.$emit("next", "pension-details");
    }
  }
};
</script>
<style lang="scss">
.toggleLabel {

}
</style>
