<template>
  <div class="container">
    <transition
      mode="out-in"
      :css="false"
      @before-enter="beforeNavEnter"
      @enter="enterNav"
      @before-leave="beforeNavLeave"
      @leave="leaveNav"
    >
      <keep-alive>
        <component @back="back" @next="next" :is="view"></component>
      </keep-alive>
    </transition>
  </div>
</template>
<script>
import Signature from "@/components/application-funnel/Signature";
import AboutYou from "@/components/application-funnel/AboutYou";
import EmploymentHistory from "@/components/application-funnel/EmploymentHistory";
import PensionDetails from "@/components/application-funnel/PensionDetails";
import ThankYou from "@/components/application-funnel/ThankYou";

export default {
  name: "ApplicationForm",
  data: () => ({
    stage: "about-you",
    view: "AboutYou"
  }),
  components: {
    Signature,
    AboutYou,
    EmploymentHistory,
    PensionDetails,
    ThankYou
  },
  methods: {
    async back(view) {
      this.view = view;
    },
    async next(view) {
      this.view = view;
    }
  }
};
</script>
