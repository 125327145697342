<template>
  <div class="container">
    <!--    <BackButton :parent-nav="true" @call-nav="handleBack" />-->
    <h4 class="mb-5">Application Form | Employment Details</h4>
    <div class="card">
      <div class="card-body">
        <Form
            :validation-schema="schema"
            class="w-100 text-left"
            @submit="handleNext"
        >
          <TextInput
              class="pr-1"
              label="Employer"
              name="name_of_employer"
              placeholder="Prudential"
              type="text"
          />
          <TextInput
              label="Role title"
              name="position"
              placeholder="Bossman"
              type="text"
          />
          <TextInput
              label="Industry"
              name="industry"
              placeholder="IT"
              type="text"
          />
          <DatePicker label="Date from" name="employed_from" placeholder=""/>
          <DatePicker label="Date to" name="employed_to" placeholder=""/>
          <a href="#" @click.prevent="handleBack">back</a>
          <button class="fabric-btn fabric-btn-submit">Next</button>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import * as Yup from "yup";

import {mapActions, mapGetters} from "vuex";
import dayjs from "dayjs";
import DatePicker from "@/components/form/DatePicker";

export default {
  name: "EmploymentHistory",
  emits: ["next"],
  setup() {
    const schema = Yup.object().shape({
      name_of_employer: Yup.string().required(),
      position: Yup.string().required(),
      industry: Yup.string().nullable()
    });
    return {
      schema
    };
  },
  components: {
    DatePicker
  },
  computed: {
    ...mapGetters([
      "getWithSig"
    ])
  },
  methods: {
    ...mapActions(["submitEmployment"]),
    async handleBack() {
      this.$emit("back", "PensionDetails");
    },
    async handleNext(params) {
      let tempParams = params;
      let provider = {
        name_of_employer: tempParams.name_of_employer,
        position: tempParams.position,
        industry: tempParams.industry,
        employed_from: tempParams.employed_from
            ? dayjs(tempParams.employed_from).format("YYYY-MM-DD")
            : null,
        employed_to: tempParams.employed_to
            ? dayjs(tempParams.employed_to).format("YYYY-MM-DD")
            : null
      };
      tempParams.employments = [];
      tempParams.employments.push(provider);
      tempParams.stage = "signature";
      let response = await this.submitEmployment({
        params: tempParams
      }).catch(err => console.log(err));
      if (response) {
        switch (this.getWithSig) {
          case true:
            this.$emit("next", "ThankYou");
            break;
          case false:
            this.$emit("next", "Signature");
            break;
        }
      }
    }
  }
};
</script>
